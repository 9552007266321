import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalkOutlined';
import EqualizerIcon from '@material-ui/icons/EqualizerOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import CommunicationIcon from '@material-ui/icons/PeopleOutlined';
import Badge from '@material-ui/core/Badge';
import EuroSymbol from '@material-ui/icons/EuroSymbolOutlined';
import Note from "@material-ui/icons/NoteOutlined";

import i18n from "../../../i18n.jsx";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import SetCookie from "../../Common/Functions/SetCookie";
import GetCookie from "../../Common/Functions/GetCookie";

import { Flush } from "../../../Actions/Flush";
import { ToggleAndSetLanguage } from "../../../Actions/Header";
import { SetArrayTab, SetTravelersInfoToShow, SetTripInfo, SetTripInfoVersion, SetTripDocs } from "../../../Actions/Menu";
import { SetFlightCart } from "../../../Actions/Flight";
import { SetCarsCart } from "../../../Actions/CarsSearch";
import { SetCart, SetSortCart } from "../../../Actions/Accommodation";
import { SetManualCart } from "../../../Actions/Cart";
import { SetTravelers, SetContactTraveler } from "../../../Actions/Trip";
import { SetTransfersCart } from "../../../Actions/Transfers";
import { SetItineraryTypeDetailsDataAndDayAndNight } from "../../../Actions/ItineraryType.js";

import GetTrip from "../../Base/Functions/GetTrip";
import GetTripMenu from "../LeftMenuPanel/Functions/GetTrip.js";

import TripListInfoHeader from "./TripListInfoHeader.tsx";
import TripListInfoMobileHeader from "./TripListInfoMobileHeader.js";
import TripListTravelersInfos from "./TripListTravelersInfos.js";
import TripListDocument from "./TripListDocuments.js";
import TripListFinance from "./TripListFinance.js";
import TripListWebsite from "./TripListWebsite.js";
import TripListQuotation from "./TripListQuotation.js";
import TripListHistory from "./TripListHistory.js";
import TripListPayment from "./TripListPayment.js";
import TripListCommunication from "./TripListCommunication.js";
import MaterialDuplicateDialog from "../../ItineraryType/MaterialDuplicateDialog.js";
import { TripListNotes } from "./TripListNotes";

import "../../../Style/Menu/TripList.css";

import axios from "axios";
import TripListInvoice from "./TripListInvoice.js";
import { LockItineraryActions } from "../../../Actions/Base";
import { detectInvoicesAnomalies } from "./Functions/detectInvoicesAnomalies";
import clsx from "clsx";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    paddingTop: {
        paddingTop: "2%"
    },
    navigationWrapper: {
        paddingTop: 8
        //overflow: 'hidden'
    },
    navigationButton: {
        textAlign: "center",
        cursor: "pointer"
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    colorOrange: {
        color: `${main_color}`
    },
    colorGrey: {
        color: "#808080"
    },
    navigationTab: {
        minWidth: "12%",
        minHeight: 60,
        fontSize: 12,
        textTransform: "capitalize",
        fontWeight: 'normal',
        '&.mui-jss-Mui-selected': {
            outline: "none"
        },
        '&:hover': {
            color: "black"
        }
    },
    stickyComponent: {
        position: "-webkit-sticky",
        position: 'sticky',
        top: 70,
        backgroundColor: "#FFFFFF",
        zIndex: 10
    },
    StickyComponentMobile: {
        position: "-webkit-sticky",
        position: "-moz-sticky",
        position: "-o-sticky",
        position: "-ms-sticky",
        position: "sticky",
        top: 71,
        backgroundColor: "#FFFFFF",
        zIndex: 10
    },
    tabsFlexContainer: {
        height: 60
    },
    footer: {
        marginTop: "calc(8% + 10px)",
        position: "sticky",
        zIndex: 10,
        bottom: 0
    },
    footerBig: {
        marginTop: "calc(40% + 20px)",
        position: "sticky",
        zIndex: 10,
        bottom: 0
    },
    footerMobile: {
        marginTop: "calc(40% + 20px)",
        position: "sticky",
        zIndex: 10,
        bottom: 64
    },
    spacer: {
        flex: '1 1 auto'
    },
    bottomButton: {
        "color": `${main_color}`,
        "borderColor": `${main_color}`,
        "backgroundColor": "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF"
        }
    },
    seeTripButton: {
        color: "#FFFFFF",
        backgroundColor: `${main_color}`
    }
}));

const TripListInfo = withRouter((props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const trip_list_filter = useSelector(store => store.menu.trip_list_filter);
    const active_page = useSelector(store => store.menu.active_page);
    const trip_list_search = useSelector(store => store.menu.trip_list_search);
    const trip_list_ordering = useSelector(store => store.menu.trip_list_ordering);
    const trip_info = useSelector(store => store.menu.trip_info);
    const trip_list_tab_nb = useSelector(store => store.menu.trip_list_tab_nb);
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const end_date = useSelector(store => store.end_date);
    const user = useSelector(store => store.user.user);
    const itinerary_list = useSelector(store => store.itinerary.itinerary_list);
    const invoices = useSelector(store => store.trip.invoices);

    const [trip_token, setTripToken] = useState(trip_info !== null ? trip_info.token : null);
    const [contact_lead, setContactLead] = useState(null);
    const [trip_displayed_version, setVersion] = useState(to_show_version !== null ? to_show_version : (trip_info !== null ? trip_info.current_version : null));
    const [missingDocs, setMissingDocs] = useState(0);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [emptyIti, setEmptyIti] = useState(false);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;

    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            checkIti();
            if (trip_info === null) {
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${props.params.trip_id}/`,
                    headers: headers
                }).then(function (response) {
                    let local_trip_info = response.data;
                    for (version_index = 0; version_index < local_trip_info.data.length; version_index++) {
                        if (local_trip_info.data[version_index].id === trip_displayed_version && local_trip_info.data[version_index].contact_traveler !== null && contact_lead === null) {
                            setContactLead(local_trip_info.data[version_index].contact_traveler_detail);
                        }
                    }
                    setTripToken(local_trip_info.token);
                    setVersion(local_trip_info.current_version);
                    dispatch(SetTripInfo(local_trip_info));
                    dispatch(SetItineraryTypeDetailsDataAndDayAndNight(local_trip_info, 0, 0));
                }).catch(function (error) {
                    console.log('error trip', error);
                });

                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${user.client}/children/?type=1,2&limit=10000&is_active=true`
                }).then(function (response) {
                    let data = response.data.results.slice();
                    data.forEach(element => {
                        element.label = `${element.name}`;
                    });
                    dispatch({
                        type: "MENU_SET_AGENCIES",
                        payload: {
                            agencies: data
                        }
                    });
                }).catch(function (error) {
                    console.log('error:', error);
                });
            } else {
                for (version_index = 0; version_index < trip_info.data.length; version_index++) {
                    if (trip_info.data[version_index].id === trip_displayed_version && trip_info.data[version_index].contact_traveler !== null && contact_lead === null) {
                        setContactLead(trip_info.data[version_index].contact_traveler_detail);
                    }
                }
                //dispatch(SetItineraryTypeDetailsDataAndDayAndNight(trip_info, 0, 0));
            }
        }
        return () => {
            dispatch(SetArrayTab(0));
        };
    }, []);
    useEffect(() => {
        checkIti();
    }, [itinerary_list]);
    useEffect(() => {
        if (trip_displayed_version !== null && trip_token !== null) {
            setInfo(true);

            //let { pass_check, headers } = CheckBeforeRequest();
            //if (pass_check){
            //axios({
            //method: "GET",
            //url: `${API_HREF}client/${window.id_owner}/trip/${props.params.trip_id}/versions/${trip_displayed_version}/travelers-group/?default=true`,
            //headers: headers,
            //}).then(function(response){
            //let ado = 1, child = 1, baby = 1, adult = 1;
            //let first_adult = null;
            //for(let index_room=0; index_room < response.data.results.length; index_room++){
            //response.data.results[index_room].travelers_list.map((passenger, passenger_index) => {
            //let age = moment.utc(end_date).diff(moment.utc(passenger.birth_date), "y");
            //if (age > 12 && age < 18) {
            //passenger.info = t("flight_search.ado") + " " + ado + " : ";
            //ado += 1;
            //} else if (age > 2 && age <= 12) {
            //passenger.info = t("flight_search.child") + " " + child + " : ";
            //child += 1;
            //} else if (age <= 2) {
            //passenger.info = t("flight_search.baby") + " " + baby + " : ";
            //baby += 1;
            //} else if (age >= 18) {
            //passenger.info = t("flight_search.adult") + " " + adult + " : ";
            //if (first_adult === null) {
            //first_adult = passenger;
            //}
            //adult += 1;
            //}
            //})
            //}
            //if (window.previousLocation === undefined) {
            //setInfo(false);
            //}
            //else {
            //delete window.previousLocation;
            //}
            //dispatch(SetTravelersInfoToShow(response.data.results));
            //
            //}).catch(function(error){
            //console.log(error.responseJSON);
            //});
            //
            //axios({
            //method: "GET",
            //url: `${API_HREF}client/${window.id_owner}/trip/${props.params.trip_id}/versions/${trip_displayed_version}/file/`,
            //headers: headers,
            //}).then(function(response){
            //dispatch(SetTripDocs(response.data.results));
            //}).catch(function(error){
            //console.log(error.responseJSON);
            //})
            //}
            //if (trip_info !== null){
            //for (let version_index=0 ; version_index < trip_info.data.length ; version_index++){
            //if (trip_info.data[version_index].id === trip_displayed_version){
            //dispatch(SetTravelers(trip_info.data[version_index].travelers))
            //}
            //}
            //}
        }
    }, [trip_displayed_version, trip_token]);

    useEffect(() => {
        let total_requested = 0;
        let total_received = 0;
        trip_docs.map((document) =>{
            if (!['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) || document.type !== "TRAVELBOOK") {
                if (document.requested === true) {
                    total_requested += 1;
                    if (document.received === true) {
                        total_received += 1;
                    }
                }
            }
        });
        let temp_missing_docs = total_requested - total_received;
        setMissingDocs(temp_missing_docs);
    }, [trip_docs]);

    useEffect(() => {
        if (to_show_version !== null) {
            setVersion(to_show_version);
        }
    }, [to_show_version]);

    useEffect(() => {
        if (contact_lead !== null) {
            for (let version_index = 0; version_index < trip_info.data.length; version_index++) {
                if (trip_info.data[version_index].id === trip_displayed_version) {
                //if (trip_info.data[version_index].id === trip_displayed_version && trip_info.data[version_index].contact_traveler !== contact_lead.id){
                    let { pass_check, headers } = CheckBeforeRequest();
                    if (pass_check) {
                        let request = {
                            contact_traveler: contact_lead.id
                        };

                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${props.params.trip_id}/versions/${trip_displayed_version}/`,
                            headers: headers,
                            data: request
                        }).then(function (response) {
                            dispatch(SetTripInfoVersion(response.data));
                            dispatch(SetContactTraveler(response.data.contact_traveler, response.data.contact_traveler_detail));
                        });
                    }
                }
            }
        }
    }, [contact_lead]);

    useEffect(() => {
        if (invoices === null && trip_info && version) {
            makeInvoicesRequest({
                trip_info,
                version
            }).then((response) => {
                dispatch({
                    type: 'TRIP_SET_INVOICES',
                    payload: response.data.results
                });
            });
        }
    }, [invoices, trip_info, version]);

    const checkIti = () => {
        if (itinerary_list.length === 0) {
            setEmptyIti(true);
        } else {
            setEmptyIti(false);
        }
        // else {
        //     let empty = false;
        //     if (itinerary_list.find((iti) => iti.step_type === "START") === undefined) {
        //         empty = true;
        //     }
        //     if (itinerary_list.find((iti) => iti.step_type === "END") === undefined) {
        //         empty = true;
        //     }
        //     if (itinerary_list.find((iti) => iti.step_type === "STEP") === undefined) {
        //         empty = true;
        //     }
        //     setEmptyIti(empty);
        // }
    };
    const setInfo = (flag) => {
        let user_token = GetCookie("token");
        let id_user = GetCookie("id_user");
        let client_user = GetCookie("client_user");
        const crmCookies = {
            crm_token: GetCookie("crm_token"),
            crm_id_user: GetCookie("crm_id_user"),
            crm_client_user: GetCookie("crm_client_user"),
            isFromCrm: GetCookie("isFromCrm")
        };
        //Delete the cookie
        let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                let p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/");
                    p.pop();
                }
                d.shift();
            }
        }
        SetCookie("trip_id", props.params.trip_id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_id_version", trip_displayed_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        if (crmCookies.crm_token) {
            for (const key in crmCookies) {
                SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
        }
        if (flag) {
            dispatch({ type: 'SMALL_FLUSH' });
        } else {
            dispatch(SetFlightCart([]));
            dispatch(SetCarsCart([]));
            dispatch(SetCart([]));
            dispatch(SetSortCart([]));
            dispatch(SetManualCart([]));
            dispatch(SetTransfersCart([]));
        }
        dispatch(ToggleAndSetLanguage(false, i18n.language));
        dispatch(LockItineraryActions());
        GetTrip(user, dispatch, t);
        GetTripMenu(active_page, trip_list_ordering, trip_list_search, trip_list_filter, dispatch, user.client);
    };

    const handleTabChange = (event, newValue) => {
        dispatch(SetArrayTab(newValue));
    };
    return (
        <Container>
            <Grid id={"trip-list"} className={classes.paddingTop}>
                {
                    trip_info !== null && (
                        <div className={"wrapper-top-content"} style={{ marginBottom: 15 }}>
                            {
                                !downDesktop ? <TripListInfoHeader contact_lead={contact_lead} setContactLead={setContactLead}/> : <TripListInfoMobileHeader contact_lead={contact_lead} setContactLead={setContactLead}/>
                            }
                        </div>
                    )
                }
            </Grid>
            <Grid>
                <Divider/>
                <Paper className={`${classes.navigationWrapper} ${!downDesktop ? classes.stickyComponent : classes.StickyComponentMobile}`} elevation={ 0 }>
                    <Tabs value={trip_list_tab_nb} onChange={handleTabChange} classes={{ flexContainer: classes.tabsFlexContainer }} style={{ height: 60, marginTop: -5 }} TabIndicatorProps={{ style: { backgroundColor: trip_list_tab_nb !== 5 ? `${main_color}` : "#FFFFFF" } }} variant="scrollable" scrollButtons="auto">
                        <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ trip_list_tab_nb === 0 ? classes.colorOrange : classes.colorGrey}` } icon={ <DirectionsWalkIcon style={{fontSize: 20}} /> } label={t("menu.trip_list.traveler_info")} value={0} />
                        {
                            (['verdie', 'continentsinsolites', 'cercledesvoyages', 'americavoyages', 'tripadekua', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'volonline'].includes(quotation_code) || (['visiteurs'].includes(quotation_code) && user.client_full.type !== 2)) && (
                                <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 1) ? classes.colorOrange : classes.colorGrey}` } icon={ <ReceiptIcon style={{fontSize: 20}} /> } label={t("menu.trip_list.quotation")} value={1} />
                            )
                        }
                        {
                            missingDocs !== 0 && (
                                <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 2) ? classes.colorOrange : classes.colorGrey}` } icon={ <Badge badgeContent={missingDocs} color="secondary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}> <AssignmentIcon style={{fontSize: 20}} /> </Badge> } label={t("menu.trip_list.documents")} value={2} />
                            )
                        }
                        {
                            missingDocs === 0 && (
                                <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 2) ? classes.colorOrange : classes.colorGrey}` } icon={ <AssignmentIcon style={{fontSize: 20}} /> } label={t("menu.trip_list.documents")} value={2} />
                            )
                        }
                        {
                            quotation_code !== "marcovasco" && (
                                <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 3) ? classes.colorOrange : classes.colorGrey}` } icon={ <LanguageIcon style={{fontSize: 20}} /> } label={t("menu.trip_list.client_website")} value={3} />
                            )
                        }
                        <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 4) ? classes.colorOrange : classes.colorGrey}` } icon={ <EqualizerIcon style={{fontSize: 20}} /> } label={t("menu.trip_list.finance")} value={4} />
                        {
                            ['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'americavoyages', 'connections', 'terreslointaines', 'touristforum'].includes(quotation_code) && (
                                <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 5) ? classes.colorOrange : classes.colorGrey}` } icon={ <EuroSymbol style={{fontSize: 20}} /> } label={t("menu.trip_list.cash_in")} value={5} />
                            )
                        }
                        {
                            (!['visiteurs'].includes(quotation_code) || user.client_full.type !== 2) && (
                                <Tab
                                    className={`
                                        ${classes.navigationButton}
                                        ${classes.navigationTab}
                                        ${ (trip_list_tab_nb === 9) ? classes.colorOrange : classes.colorGrey}
                                    `}
                                    icon={ <Note style={{fontSize: 20}} /> }
                                    label={t("menu.trip_list.notes")}
                                    value={9}
                                />
                            )
                        }
                        {
                            ['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'americavoyages', 'connections', 'terreslointaines', 'touristforum'].includes(quotation_code) && (
                                <Tab
                                    className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 7) ? classes.colorOrange : classes.colorGrey}` }
                                    icon={
                                        detectInvoicesAnomalies(invoices).length > 0 ?
                                            <Badge variant="dot" color="error">
                                                <PaymentIcon style={{fontSize: 20}} />
                                            </Badge> :
                                            <PaymentIcon style={{fontSize: 20}} />
                                    }
                                    label={t("menu.trip_list.receipt")}
                                    value={7}
                                />
                            )
                        }
                        <Tab
                            className={`
                                ${classes.navigationButton}
                                ${classes.navigationTab}
                                ${ (trip_list_tab_nb === 8) ? classes.colorOrange : classes.colorGrey}
                            `}
                            icon={ <CommunicationIcon style={{fontSize: 20}} /> }
                            label={t("menu.trip_list.communication")}
                            value={8}
                        />
                        <Tab className={ `${classes.navigationButton} ${classes.navigationTab} ${ (trip_list_tab_nb === 6) ? classes.colorOrange : classes.colorGrey}` } icon={ <MessageIcon /> } label={t("menu.trip_list.history")} value={6} />
                    </Tabs>
                </Paper>
                {
                    trip_info !== null && (
                        <div>
                            {
                                trip_list_tab_nb === 0 && (
                                    <TripListTravelersInfos trip_displayed_version={trip_displayed_version} contact_lead={contact_lead} setContactLead={setContactLead} />
                                )
                            }
                            {
                                trip_list_tab_nb === 1 && (['verdie', 'continentsinsolites', 'cercledesvoyages', 'americavoyages', 'tripadekua', 'tropicalementvotre', 'terreslointaines', 'touristforum', 'volonline'].includes(quotation_code) || (['visiteurs'].includes(quotation_code) && user.client_full.type !== 2)) && (<TripListQuotation trip_displayed_version={trip_displayed_version} />)
                            }
                            {
                                trip_list_tab_nb === 2 && (<TripListDocument trip_displayed_version={trip_displayed_version} />)
                            }
                            {
                                trip_list_tab_nb === 3 && quotation_code !== "marcovasco" && quotation_code !== "amerigo" && (<TripListWebsite />)
                            }
                            {
                                trip_list_tab_nb === 4 && (<TripListFinance />)
                            }
                            {
                                trip_list_tab_nb === 5 && (<TripListPayment/>)
                            }
                            {
                                trip_list_tab_nb === 6 && (<TripListHistory />)
                            }
                            {
                                trip_list_tab_nb === 7 && (<TripListInvoice />)
                            }
                            {
                                trip_list_tab_nb === 8 && (<TripListCommunication />)
                            }
                            {
                                trip_list_tab_nb === 9 && (<TripListNotes />)
                            }
                        </div>
                    )
                }
            </Grid>
            <Grid container justify={ "space-evenly" } spacing={ 2 } className={ !downDesktop ? (trip_list_tab_nb !== 2 ? classes.footer : classes.footerBig) : classes.footerMobile }>
                {
                    !mobile && (
                        <div className={classes.spacer} />
                    )
                }
                {
                    ['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) && (
                        <Grid item>
                            <Button variant="outlined" className={classes.bottomButton} size="small" onClick={() => {
                                dispatch({
                                    type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_VERSION",
                                    payload: {
                                        itinerary_type_details_data: trip_info,
                                        version: trip_displayed_version
                                    }
                                });
                                setDuplicationOpen(true);
                            }}>
                                { !mobile ? t("menu.trip_list.duplicate_trip") : t("global.duplicate")}
                            </Button>
                        </Grid>
                    )
                }
                {
                    trip_displayed_version !== null && trip_token !== null && !emptyIti && !['verdie'].includes(quotation_code) && (
                        <Grid item>
                            <Button variant="outlined" className={clsx({ [classes.bottomButton]: !['cercledesvoyages', 'tropicalementvotre', 'terreslointaines', 'touristforum', 'continentsinsolites'].includes(quotation_code), [classes.seeTripButton]: ['cercledesvoyages', 'tropicalementvotre', 'terreslointaines', 'touristforum', 'continentsinsolites'].includes(quotation_code) })} size="small"onClick={() => {
                                if (!['cercledesvoyages', 'americavoyages', 'beachcomber', 'tropicalementvotre', 'connections', 'autigrevanille', 'terreslointaines', 'touristforum', 'continentsinsolites'].includes(quotation_code)) {
                                    window.open(`/${window.url_name}/summary-quotation/${props.params.trip_id}/${trip_displayed_version}/${trip_token}`, "_blank");
                                } else {
                                    window.open(`${window.origin}/quotation/trip?trip=${trip_info.id}&token=${trip_info.token}`, "_blank");
                                }
                            }}>
                                {['cercledesvoyages', 'americavoyages', 'beachcomber', 'tropicalementvotre', 'autigrevanille', 'terreslointaines', 'touristforum', 'continentsinsolites'].includes(quotation_code) ? t("menu.trip_list.traveler_website") : (!mobile ? t("menu.trip_list.client_website") : t("global.website"))}
                            </Button>
                        </Grid>
                    )
                }
                {
                    !['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) && (
                        <Grid item>
                            <Button variant="outlined" className={classes.bottomButton} size="small" onClick={() => {
                                dispatch({
                                    type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_VERSION",
                                    payload: {
                                        itinerary_type_details_data: trip_info,
                                        version: trip_displayed_version
                                    }
                                });
                                setDuplicationOpen(true);
                            }}>
                                { !mobile ? t("menu.trip_list.duplicate_trip") : t("global.duplicate")}
                            </Button>
                        </Grid>
                    )
                }
                {
                    !['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) && (
                        <Grid item>
                            {
                                trip_displayed_version !== null && (
                                    <Button variant="contained" className={classes.seeTripButton} size="small" onClick={() => {
                                        setInfo(true);
                                        props.router.push(`/${window.url_name}/apps/itinerary`);
                                    }} >
                                        { !mobile ? t("menu.trip_list.see_version") : t("global.see")}
                                    </Button>
                                )
                            }
                        </Grid>
                    )
                }
            </Grid>
            <MaterialDuplicateDialog isFrom={"tripList"} duplicationOpen={duplicationOpen} setDuplicationOpen={setDuplicationOpen} router={props.router}/>
        </Container>
    );
});

export default React.memo(TripListInfo);

function makeInvoicesRequest(options) {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.trip_info.id}/invoice-history/`,
            {
                headers,
                params: { limit: 10000 }
            }
        );
    }

    return null;
}

import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import GetProductType from "../TripList/Functions/GetProductType";
import GetProvider from "../../Common/Functions/GetProvider";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import ConvertPrice from "../../Cart/Functions/Manual/ConvertPrice";
import clsx from "clsx";
import { Check, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";

const useRowStyles = makeStyles({
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    green: {
        color: "#4caf50"
    },
    orange: {
        color: "#ff9800"
    },
    black: {
        color: "black"
    },
    red: {
        color: "#f44336"
    },
    bold: {
        fontWeight: "bold"
    },
    hiddenProduct: {
        backgroundColor: "#eeee",
    }
});

const RenderItem = ({ item, providers, manual_providers, language, list_currency, owner_id, user, currency }) => {
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const classes = useRowStyles();
    const getTaxesPrice = useGetTaxesPrice();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const finance_charges = useSelector(store => store.menu.finance_charges);
    const getPrice = useGetPrice();

    let product_type = GetProductType(item.product_type);
    let provider = null;
    const locale = useSelector((state) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const localization = item.localization?.find((item) => {
        return item.locale === locale;
    });
    if (item.provider !== undefined && item.provider !== null && providers.length !== 0 && (manual_providers.length !== 0 || (manual_providers.length !== 0 || ['marcovasco'].includes(quotation_code)))) {
        let tmp_provider = GetProvider(providers, item.provider);
        if (tmp_provider === null) {
            tmp_provider = GetProvider(manual_providers, item.provider);
        }
        provider = tmp_provider.provider.name;
    }
    if (item.provider_name !== undefined && item.provider_name !== null) {
        provider = item.provider_name;
    }
    let name = "-";
    if (item.name !== undefined && item.name !== null) {
        name = (localization?.name.trim().length ?? 0) > 0 ? localization?.name : item.name;
    } else {
        if (item.product_type === 0) {
            name = t("accommodation.hotel") + " : " + ((localization?.name.trim().length ?? 0) > 0 ? localization?.name : item.hotel[0].name);
        }
        if (item.product_type === 6) {
            if (item.segments !== undefined && item.segments !== null) {
                name = item.segments[0].marketing_airline.commercial_name + " : " + (item.segments[0].origin !== null && item.segments[0].destination !== null ? item.segments[0].origin.airport_code + " - " + item.segments[0].destination.airport_code : item.segments[0].origin_station.station_code + " - " + item.segments[0].destination_station.station_code);
            }
        }
        if (item.product_type === 11 || item.product_type === 12) {
            if (item.custom_product !== undefined) {
                name = (localization?.name.trim().length ?? 0) > 0 ? localization?.name : item.custom_product.title;
            }
        }
        if (item.product_type === 8) {
            name = (localization?.name.trim().length ?? 0) > 0 ? localization?.name : (!!item.contract_name ? item.contract_name : t('cart-material.assistance'));
        }
        if (item.product_type === 4) {
            if (item.custom_product !== undefined) {
                name = (localization?.name.trim().length ?? 0) > 0 ? localization?.name : item.custom_product.title;
            }
        }
    }
    let booking_ref = null;
    let booking_status = "";
    if (item.booking_reference !== undefined && item.booking_reference !== null) {
        booking_ref = item.booking_reference;
    }
    if (item.booking_status !== undefined && item.booking_status !== null) {
        booking_ref = item.booking_status.item_reference;
        booking_status = (item.booking_status.status_booking).toLowerCase();
    }
    let price = parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, true)].purchase_price);
    let selling_price = parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
    let currency_buy = list_currency.find((currency) => {
        return currency.id === item.prices[GetPricesId(item.prices, owner_id, user, true)].purchase_currency
    });
    let currency_sell = list_currency.find((currency) => {
        return currency.id === item.prices[GetPricesId(item.prices, owner_id, user, quotation_code !== "americavoyages")].selling_currency
    });
    let currency_real_purchase = item.purchase_currency !== undefined && list_currency.find((currency) => {
        return currency.id === item.purchase_currency
    });
    if (item.product_type === 6) {
        const master_price = item.prices.find((price) => price.master_price);
        const provider = providers.concat(manual_providers).find((item) => {
            return item.provider.id === item.provider;
        })?.provider;
        let seatsCost = 0;
        let option_cost = 0;
        let otherOptionsCost = 0;
        let option_on_flight = [];
        if (provider?.code !== 'amadeus_rest') {
            let outbounds = item.segments || item.outbounds;
            outbounds.map(outbound => {
                let option_on_group = [];
                if (outbound.legs !== undefined) {
                    outbound.legs.map(leg => {
                        for (const seat of leg.seats ?? []) {
                            let seatPrice = 0;
                            if ((seat.prices?.length ?? 0) > 0) {
                                seatPrice = getPrice(seat.prices).cost;
                            } else {
                                seatPrice = parseFloat(seat.price ?? '0');
                            }
                            seatsCost += seatPrice;
                        }
    
                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map(option => {
                                let optionPrice = 0;
                                if ((option.prices?.length ?? 0) > 0) {
                                    optionPrice = getPrice(option.prices, undefined, force_network).cost;
                                } else {
                                    optionPrice = parseFloat(option.price);
                                }
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    option_cost += optionPrice;
                                }
                            }); 
                        }
                    });
                } else {
                    for (const seat of outbound.seats ?? []) {
                        let seatPrice = 0;
                        if ((seat.prices?.length ?? 0) > 0) {
                            seatPrice = getPrice(seat.prices).cost;
                        } else {
                            seatPrice = parseFloat(seat.price ?? '0');
                        }
                        seatsCost += seatPrice;
                    }

                    if (outbound.paid_options && outbound.paid_options.length > 0) {
                        outbound.paid_options.map(option => {
                            let optionPrice = 0;
                            if ((option.prices?.length ?? 0) > 0) {
                                optionPrice = getPrice(option.prices, undefined, force_network).cost;
                            } else {
                                optionPrice = parseFloat(option.price);
                            }
                            if (option.option_on_flight) {
                                if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else if (option.option_on_group) {
                                if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else {
                                option_cost += optionPrice;
                            }
                        }); 
                    }
                }
            });
        }
        else {
            seatsCost = item.paid_option_price.filter((item) => {
                return item.service_type === 'SEATS';
            }).reduce((prev, current) => {
                return prev + getPrice(current.prices).cost;
            }, 0);
            otherOptionsCost = item.paid_option_price.filter((item) => {
                return item.service_type !== 'SEATS';
            }).reduce((prev, current) => {
                return prev + getPrice(current.prices, undefined, force_network).cost;
            }, 0);
        }
        price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
        selling_price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
        if (item.taxes !== undefined && item.taxes !== null) {
            price += getTaxesPrice(item.taxes);
            // price += getTaxesPrice(ConvertPrice(getTaxesPrice(item.taxes), currency, currency_buy, master_price.factor_used));
            selling_price += parseFloat(ConvertPrice(getTaxesPrice(item.taxes), currency, currency_buy, parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, true)].factor_used)));
        }
    } 
    let converted_price = parseFloat(ConvertPrice(price, currency, currency_buy, parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, true)].factor_used)));
    // if (item.taxes !== undefined && item.taxes !== null)
    //     converted_price += parseFloat(ConvertPrice(getTaxesPrice(item.taxes), currency, currency_buy, parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, true)].factor_used)));
    return (
        <TableRow className={clsx(classes.paddingCell, {[classes.hiddenProduct]: item.is_hidden_for_traveler})}>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{item.client_start_date !== undefined && item.client_start_date !== null ? moment.utc(item.client_start_date).format("DD/MM/YYYY") + " " + t("global.to") : (item.start_date !== undefined && item.start_date !== null ? moment.utc(item.start_date).format("DD/MM/YYYY") + " " + t("global.to") : "")} {item.client_end_date !== undefined && item.client_end_date !== null ? moment.utc(item.client_end_date).format("DD/MM/YYYY") : (item.end_date !== undefined && item.end_date !== null ? moment.utc(item.end_date).format("DD/MM/YYYY") : "")}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ t("providers." + product_type) }</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ provider !== null ? provider : "-"}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{name}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={clsx(classes.fontSize12, {
                    [classes.green]: booking_status !== "" && booking_status === "confirmed",
                    [classes.orange]: booking_status !== "" && (booking_status === "waiting" || booking_status === "pending"),
                    [classes.red]: booking_status !== "" && (booking_status === "error" || booking_status === "unavailable"),
                    [classes.black]: booking_status !== "" && booking_status === "cancelled"
                })}>{ booking_ref !== null ? booking_ref : "-" }</span>
            </TableCell>
            <TableCell align={"left"}>
                {
                    (booking_status === "" || booking_status === 'unavailable') && !item.is_available &&
                    <span className={`${classes.fontSize12} ${classes.red} ${classes.bold}`}>{ t('cart-material.product-unavailable') }</span>
                }
                {
                    booking_status === "" && item.is_available && (
                        <span className={`${classes.fontSize12}`}>{ "-" }</span>
                    )
                }
                {
                    booking_status !== "" && booking_status === "confirmed" && (
                        <span className={`${classes.fontSize12} ${classes.green} ${classes.bold}`}>{ t(`cart.edit_booking.${booking_status}`) }</span>
                    )
                }
                {
                    booking_status !== "" && (booking_status === "waiting" || booking_status === "pending") && (
                        <span className={`${classes.fontSize12} ${classes.orange} ${classes.bold}`}>{ t(`cart.edit_booking.${booking_status}`) }</span>
                    )
                }
                {
                    booking_status !== "" && booking_status === "error" && (
                        <span className={`${classes.fontSize12} ${classes.red} ${classes.bold}`}>{ t(`cart.edit_booking.${booking_status}`) }</span>
                    )
                }
                {
                    booking_status !== "" && booking_status === "cancelled" && (
                        <span className={`${classes.fontSize12} ${classes.black} ${classes.bold}`}>{ t(`cart.edit_booking.${booking_status}`) }</span>
                    )
                }
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ item.charge !== undefined ? new Intl.NumberFormat(language, { style: 'currency', currency: item.origin_charge_currency.iso_code }).format(item.origin_charge.toFixed(2)) : new Intl.NumberFormat(language, { style: 'currency', currency: currency_buy.iso_code }).format(price.toFixed(2)) }</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ item.charge !== undefined ? parseFloat(item.charge_factor_used).toFixed(2) : parseFloat(item.prices[GetPricesId(item.prices, owner_id, user, true)].factor_used).toFixed(2) }</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ item.charge !== undefined ? new Intl.NumberFormat(language, { style: 'currency', currency: item.charge_currency.iso_code }).format(item.charge.toFixed(2)) : new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(converted_price.toFixed(2)) }</span>
            </TableCell>
            {
                finance_charges.length !== 0 &&
                <TableCell align={"left"}>
                    <span className={classes.fontSize12}>{ item.purchase !== undefined && currency_real_purchase !== undefined ? new Intl.NumberFormat(language, { style: 'currency', currency: currency_real_purchase.iso_code }).format(item.purchase.toFixed(2)) : new Intl.NumberFormat(language, { style: 'currency', currency: currency_buy.iso_code }).format(price.toFixed(2)) }</span>
                </TableCell>
            }
            {/* <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(converted_price).toFixed(2)) }</span>
            </TableCell> */}
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency_sell.iso_code }).format(selling_price.toFixed(2)) }</span>
            </TableCell>
            <TableCell align={"left"}>
                <span className={classes.fontSize12}>{item.is_paid ? <Check sx={{color: "#4caf50"}}/> : <Close sx={{color: "#f44336"}}/>}</span>
            </TableCell>
        </TableRow>
    );
};
export default React.memo(RenderItem);

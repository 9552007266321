import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, withStyles } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";

import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import ChildCareIcon from "@material-ui/icons/ChildCare";
import AccessibleIcon from '@material-ui/icons/Accessible';
import LowPriorityOutlinedIcon from '@material-ui/icons/LowPriorityOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import Rating from "@mui/material/Rating";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepContent from "@mui/material/StepContent";

import moment from "moment";
import localeText from "../../Functions/localeText";
import LocaleTextArray from "../../Utils/localeText/localeTextArray";
import i18n from "../../../i18n";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    spacer: {
        padding: "8px 0px"
    },
    bold: {
        fontWeight: "bold"
    },
    grey: {
        color: grey[800]
    },
    italic: {
        fontStyle: "italic"
    },
    icon: {
        paddingTop: 4
    }
}));

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const AboutPoi = ({ activity, manualVariant, variantChosen }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const start_date = useSelector(state => state.poi.start_date);
    const meeting_point = useSelector(state => state.poi.meeting_point);
    const pickup_point = useSelector(state => state.poi.pickup_point);
    const return_point = useSelector(state => state.poi.return_point);
    const language = useSelector(state => state.header.tmp_language);
    const locales = useSelector(state => state.user.locales);
    const manual_providers = useSelector(state => state.trip.manual_providers);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [openTime, setOpenTime] = useState(null);
    const [viatorLocation, setViatorLocation] = useState(null);
    const [doneLocation, setDoneLocation] = useState(false);
    const ticket_info = activity.ticket_info !== undefined && activity.ticket_info !== null ? JSON.parse(activity.ticket_info) : null;
    const reviews = activity.reviews !== undefined && activity.reviews !== null ? JSON.parse(activity.reviews) : null;
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    let reviews_source = '';
    if (reviews !== undefined && reviews !== null && reviews.totalReviews !== 0) {
        if (reviews.sources !== undefined && reviews.sources.length > 0) {
            reviews_source = reviews.sources.reduce((acc, curr) => {
                return acc += (acc.length > 0 ? ', ' : '') + capitalize((curr.provider).toLowerCase()); 
            }, '');
        }
    }
    if (activity !== null) {
        if (activity.provider !== undefined && activity.provider !== null) {
            provider = activity.provider;
        } else if (activity.providers !== undefined && activity.providers.length > 0) {
            if (typeof activity.providers[0] === 'number') {
                provider = manual_providers.find((provider) => {
                    return provider.id === activity.providers[0];
                });
            } else {
                provider = activity.providers[0];
            }
        }
    }

    let cancellation = null;
    if (activity.cancelation !== undefined && activity.cancelation !== null) {
        cancellation = JSON.parse(localeText(current_locale.id, activity.localization, activity.cancelation, 'cancelation'));
    }
    let activity_itinerary = null;
    if (activity.itinerary !== undefined && activity.itinerary !== null) {
        activity_itinerary = JSON.parse(localeText(current_locale.id, activity.localization, activity.itinerary, 'itinerary'));
    }

    let provider = null;
    if (activity !== null) {
        if (activity.provider !== undefined && activity.provider !== null) {
            provider = activity.provider;
        } else if (activity.providers !== undefined && activity.providers.length > 0) {
            if (typeof activity.providers[0] === 'number') {
                provider = manual_providers.find((provider) => {
                    return provider.id === activity.providers[0];
                });
            } else {
                provider = activity.providers[0];
            }
        }
    }
    useEffect(() => {
        if (doneLocation && viatorLocation !== null) {
            let google_place = checkGoogleLocation();
            Promise.all(google_place).then(res => {
                console.log('res:', res);
                setViatorPlace(res);
                setDoneLocation(false);
            }).catch(err => {
                console.log(err);
                setDoneLocation(false);
            });
        }
    }, [doneLocation]);
    useEffect(() => {
        if (activity !== null && activity.schedules !== undefined && activity.schedules !== null) {
            activity.schedules.map((schedule) => {
                if (schedule.contract !== null && moment.utc(start_date).isBetween(moment.utc(schedule.contract.start_date), moment.utc(schedule.contract.end_date))) {
                    setCurrentSchedule(schedule);
                }
            });
        }
        if (activity !== null && activity.provider !== undefined && activity.provider !== null && activity.provider.code === "viator") {
            getViatorLocation();
        }
    }, [activity]);
    useEffect(() => {
        return () => {
            dispatch({ type: 'POI_SET_MEETING_POINT', payload: null });
            dispatch({ type: 'POI_SET_PICKUP_POINT', payload: null });
            dispatch({ type: 'POI_SET_RETURN_POINT', payload: null });
        };
    }, []);
    useEffect(() => {
        if (viatorLocation !== null) {
            if (viatorLocation.locations.length === 1) {
                dispatch({ type: 'POI_SET_MEETING_POINT', payload: { ...viatorLocation.locations[0] } });
            }
            if (viatorLocation.start.length === 1) {
                dispatch({ type: 'POI_SET_PICKUP_POINT', payload: { ...viatorLocation.start[0] } });
            }
            if (viatorLocation.end.length === 1) {
                dispatch({ type: 'POI_SET_RETURN_POINT', payload: { ...viatorLocation.end[0] } });
            }
        }
    }, [viatorLocation]);
    useEffect(() => {
        if (currentSchedule !== null) {
            let temp_open_time = "";

            if (currentSchedule.type === "TO_SET") {
                temp_open_time = `${t("poi.all_year")} ${t("poi.from")} ${t("poi.week_days.0")} ${t("poi.to")} ${t("poi.week_days.6")}, ${t("poi.entire_day")}`;
            } else {
                if (currentSchedule.months.length === 12) {
                    temp_open_time = t("poi.all_year");
                } else {
                    let months = currentSchedule.months.reduce((accumulator, month) => {
                        let lastSubArray = accumulator[accumulator.length - 1];
                        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== month - 1) {
                            accumulator.push([]);
                        }
                        accumulator[accumulator.length - 1].push(month);
                        return accumulator;
                    }, []);

                    
                    for (let i = 0; i < months.length; i++) {
                        if (months[i].length > 1) {
                            temp_open_time += `${(i > 0 ? (months[i][0] === 3 || months[i][0] === 7 || months[i][0] === 9 ? " " + t("poi.and_month_from_2") : " " + t("poi.and_month_from_1") + " ") : (months[i][0] === 3 || months[i][0] === 7 || months[i][0] === 9 ? t("poi.from_month_2") : t("poi.from_month_1") + " "))}${t("poi.months." + months[i][0])} ${t("poi.to_month")} ${t("poi.months." + months[i][months[i].length - 1])}`;
                        } else {
                            temp_open_time += `${(i > 0 ? " " + t("poi.and_2") + " " : "")}${t("poi.months." + months[i][0])}`;
                        }
                    }
                }

                temp_open_time += ", ";

                const days = currentSchedule.days.reduce((accumulator, day) => {
                    const lastSubArray = accumulator[accumulator.length - 1];
                    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== day - 1) {
                        accumulator.push([]);
                    }
                    accumulator[accumulator.length - 1].push(day);
                    return accumulator;
                }, []);

                for (let i = 0; i < days.length; i++) {
                    if (days[i].length > 1) {
                        temp_open_time += `${(i > 0 ? " " + t("poi.and_from") : t("poi.from"))} ${t("poi.week_days." + days[i][0])} ${t("poi.to")} ${t("poi.week_days." + days[i][days[i].length - 1])}`;
                    } else {
                        temp_open_time += `${(i > 0 ? " " + t("poi.and") + " " : "")}${t("poi.week_days." + days[i][0])}`;
                    }
                }

                for (let i = 0; i < currentSchedule.hours.length; i++) {
                    if (currentSchedule.hours[i].length === 1) {
                        temp_open_time += `${i === 0 ? " " + t("poi.to_month") : (i === currentSchedule.hours.length - 1 ? " " + t("poi.and_2") : ",")} ${currentSchedule.hours[i][0].slice(0, -3).replace(":", "h")}`;
                    } else {
                        temp_open_time += `${i === 0 ? " " + t("poi.from_hour") : (i === currentSchedule.hours.length - 1 ? " " + t("poi.and_month_from_1") : ",")} ${currentSchedule.hours[i][0].slice(0, -3).replace(":", "h")} ${t("poi.to_month")} ${currentSchedule.hours[i][1].slice(0, -3).replace(":", "h")}`;
                    }
                }
            }
            setOpenTime(temp_open_time);
        }
    }, [currentSchedule]);
    const setViatorPlace = (places) => {
        let tmp_viator = { ...viatorLocation };
        let tmp_start = [];
        let tmp_end = [];
        let tmp_locations = [];
        let copy_data = {
            start: [],
            locations: [],
            end: [],
            meeting_type: tmp_viator.meeting_type
        };
        if (viatorLocation.start.length !== 0) {
            viatorLocation.start.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    let find_place = places.find(place => place.reference === tmp_item.google_ref);
                    if (find_place !== undefined) {
                        tmp_item.address = find_place.formatted_address;
                        tmp_item.name = find_place.name;
                        tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                        tmp_item.latitude = find_place.geometry.location.lat();
                        tmp_item.longitude = find_place.geometry.location.lng();
                        tmp_item.pick_up_type = "OTHER";
                        tmp_item.ref = tmp_item.google_ref;
                        tmp_start.push(tmp_item);
                    }
                } else if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                    tmp_start.push(tmp_item);
                }
            });
        }
        if (viatorLocation.end.length !== 0) {
            viatorLocation.end.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    let find_place = places.find(place => place.reference === tmp_item.google_ref);
                    if (find_place !== undefined) {
                        console.log('find_place:', find_place);
                        tmp_item.address = find_place.formatted_address;
                        tmp_item.name = find_place.name;
                        tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                        tmp_item.latitude = find_place.geometry.location.lat();
                        tmp_item.longitude = find_place.geometry.location.lng();
                        tmp_item.ref = tmp_item.google_ref;
                        tmp_item.pick_up_type = 'OTHER';
                        tmp_end.push(tmp_item);
                    }
                } else if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                    tmp_end.push(tmp_item);
                }
            });
        }
        if (viatorLocation.locations.length !== 0) {
            viatorLocation.locations.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    let find_place = places.find(place => place.reference === tmp_item.google_ref);
                    if (find_place !== undefined) {
                        tmp_item.address = find_place.formatted_address;
                        tmp_item.name = find_place.name;
                        tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                        tmp_item.latitude = find_place.geometry.location.lat();
                        tmp_item.longitude = find_place.geometry.location.lng();
                        tmp_item.ref = tmp_item.google_ref;
                        tmp_item.pick_up_type = 'OTHER';
                        tmp_locations.push(tmp_item);
                    }
                } else if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                    tmp_locations.push(tmp_item);
                }
            });
        }
        if (tmp_locations.length === 1) {
            dispatch({ type: 'POI_SET_MEETING_POINT', payload: { ...tmp_locations[0] } });
        }
        if (tmp_start.length === 1) {
            dispatch({ type: 'POI_SET_PICKUP_POINT', payload: { ...tmp_start[0] } });
        }
        if (tmp_end.length === 1) {
            dispatch({ type: 'POI_SET_RETURN_POINT', payload: { ...tmp_end[0] } });
        }
        copy_data.end = [...tmp_end];
        copy_data.locations = [...tmp_locations];
        copy_data.start = [...tmp_start];
        setViatorLocation({ ...copy_data });

        setDoneLocation(false);
    };
    const checkGoogleLocation = () => {
        let google_request = [];
        if (viatorLocation.start.length !== 0) {
            viatorLocation.start.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    google_request.push(getGoogleLocation(tmp_item.google_ref));
                }
            });
        }
        if (viatorLocation.end.length !== 0) {
            viatorLocation.end.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    google_request.push(getGoogleLocation(tmp_item.google_ref));
                }
            });
        }
        if (viatorLocation.locations.length !== 0) {
            viatorLocation.locations.map(item => {
                let tmp_item = { ...item };
                if (tmp_item.provider_type === "GOOGLE") {
                    google_request.push(getGoogleLocation(tmp_item.google_ref));
                }
            });
        }
        return google_request;
    };

    const getGoogleLocation = (place_id) => {
        let detail_request = {
            placeId: place_id
        };
        let detail_service = new google.maps.places.PlacesService(document.createElement('div'));
        return new Promise(function (resolve, reject) {
            detail_service.getDetails(detail_request, (place, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject(status);
                }
            });
        });
    };
    const getViatorLocation = async () => {
        const { headers } = CheckBeforeRequest();
        Axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/custom-products/list_meeting_point_viator/?product=${activity.product_code}`
        }).then(res => {
            if (res.data !== undefined && res.data !== null) {
                let data = { ...res.data };
                setViatorLocation({ ...data });
                setDoneLocation(true);
            }
        }).catch(err => {
            console.log(err);
        });
    };
    const renderReview = (review, total_review) => {
        let reverse_review = [...review].reverse();
        return (
            reverse_review.map((item, index) => {
                let value = (item.count / total_review) * 100;
                return (
                    <Grid item container justify={'space-between'} spacing={ 2 } key={parseFloat(item.rating)} alignItems="center">
                        <Grid item xs={2} style={{ fontWeight: item.count > 0 ? 'bold' : '', color: item.count > 0 ? '#E6592F' : '' }}>
                            {
                                parseFloat(item.rating) === 1 ? t('poi.star', { star: parseFloat(item.rating) }) :
                                    t('poi.stars', { star: parseFloat(item.rating) })
                            }
                        </Grid>
                        <Grid item xs={8}>
                            <BorderLinearProgress variant="determinate" value={value} />
                        </Grid>
                        <Grid item xs={2} style={{ fontWeight: item.count > 0 ? 'bold' : '', color: item.count > 0 ? '#E6592F' : '' }}>
                            {item.count}
                        </Grid>
                    </Grid>
                );
            })
        );
    };
    return (
        <Grid>
            <br/>
            <Grid container alignItems="center" justify="space-between" direction={mobile ? "column" : "row"}>
                <Grid item>
                    <Typography variant="h6"> {t("poi.about") + " : "} </Typography>
                </Grid>
                <Grid item>
                    {
                        //(user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity.is_custom && provider.provider.logo !== null && (
                        //<img src={ provider.provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 65 }} />
                        //)
                    }
                    {
                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && !activity.is_custom && provider.logo !== null && (
                            <img src={ provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 65 }} />
                        )
                    }
                    {
                        //(user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && activity.is_custom && provider.provider.logo === null && (
                        //<Typography className={ classes.grey }>{ provider.provider.name }</Typography>
                        //)
                    }
                    {
                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && !activity.is_custom && provider.logo === null && (
                            <Typography className={ classes.grey }>{ provider.name }</Typography>
                        )
                    }
                </Grid>
            </Grid>
            <br/>
            {
                activity.taking_address !== undefined && activity.taking_address !== null && activity.taking_address !== "" && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <PlaceOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                { t("poi.taking_address") + " : " + activity.taking_address }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                currentSchedule !== null && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <CheckIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                { t("poi.availability") + ' : ' + (currentSchedule.contract.stock_type === "ON_DEMAND" ? t("poi.on_demand") : t("poi.immediate_availability")) }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.variants !== undefined && manualVariant !== null && !manualVariant.multiple_days &&
                    <Fragment>
                        {
                            manualVariant.duration >= 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(manualVariant.duration / 1440) } { Math.floor(manualVariant.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            manualVariant.duration >= 60 && manualVariant.duration < 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(manualVariant.duration / 60) } { Math.floor(manualVariant.duration / 60) > 1 ? t("poi.hours") : t("poi.hour")} {(manualVariant.duration % 60) !== 0 ? manualVariant % 60 + ' ' + t('poi.minutes') : ''}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            manualVariant.duration < 60 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { manualVariant.duration } {t("poi.minutes")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Fragment>
            }
            {
                activity.variants !== undefined && manualVariant !== null && manualVariant.multiple_days &&
                    <Grid container direction={'column'} alignItems={"flex-start"} justify={"flex-start"} spacing={mobile ? 0 : 2} className={classes.spacer}>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                {`${t('poi.multiple_days')} ${manualVariant.consecutive_day ? t('poi.consecutive_days') : t('poi.non_consecutive_days')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={ 4 }>
                                <Grid item>
                                    <Typography className={ classes.grey }>
                                        {`${t('poi.day_number')} : ${Math.floor(manualVariant.duration / 1440)} ${Math.floor(manualVariant.duration / 1440) > 1 ? t("poi.days") : t("poi.day")}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={ classes.grey }>
                                        {`${t('poi.daily_duration')} : `}
                                        {
                                            manualVariant.daily_duration >= 60 && manualVariant.daily_duration < 1440 &&
                                            <Fragment>{ Math.floor(manualVariant.daily_duration / 60) } { Math.floor(manualVariant.daily_duration / 60) > 1 ? t("poi.hours") : t("poi.hour")}</Fragment>
                                        }
                                        {
                                            manualVariant.daily_duration < 60 &&
                                            <Fragment>{ manualVariant.daily_duration } {t("poi.minutes")}</Fragment>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            }
            {
                activity.variants === undefined &&
                    <Fragment>
                        {
                            activity.duration >= 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(activity.duration / 1440) } { Math.floor(activity.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            activity.duration >= 60 && activity.duration < 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { (activity.duration / 60).toFixed(1) } { activity.duration / 60 > 1 ? t("poi.hours") : t("poi.hour")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            activity.duration < 60 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { activity.duration } {t("poi.minutes")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Fragment>
            }
            {
                activity.max_passengers !== undefined && activity.max_passengers !== null && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <PeopleOutlineOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                { activity.max_passengers + " " + t("poi.max_pax") }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.max_pax !== undefined && activity.max_pax !== null && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <PeopleOutlineOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                { activity.max_pax + " " + t("poi.max_pax") }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.need_pax_weight && (
                    <Grid>
                        <br />
                        <Typography className={classes.bold} variant="body1"> { t("poi.requirements") + " :" } </Typography>
                        <br/>
                        <Typography variant="body2" className={classes.italic}> { t("poi.need_weight") } </Typography>
                    </Grid>
                )
            }
            {
                activity.audio_guide && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <GraphicEqIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> {t("poi.audio_guide")} </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.guide && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <EmojiPeopleIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> { t("poi.guide_included") } </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                (activity.guide || activity.audio_guide) && activity.guide_lang !== null && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <TranslateIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> { t("poi.guide_lang") + " : " + activity.guide_lang } </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.special_attribute === "GUIDE" && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <SupervisedUserCircleOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> { t("poi.guide") } </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.special_attribute === "TRANSPORT_PASS" && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <LoyaltyOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> { t("poi.transport_pass") } </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.for_children && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <ChildCareIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> {t("poi.for_children")} </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.for_disabled && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <AccessibleIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> {t("poi.for_disabled")} </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.cut_line && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <LowPriorityOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> {t("poi.cut_line")} </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.multiple_days !== null && activity.multiple_days && (
                    <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                        <Grid item>
                            <DateRangeOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography className={ classes.grey }> {t("poi.multiple_days")} </Typography>
                        </Grid>
                    </Grid>
                )
            }
            <br />
            <Divider />
            <br />
            {
                ((localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '' || (manualVariant !== '' && localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '')) || (localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '' || (manualVariant !== '' && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== ''))) &&
                <Grid>
                    <Typography variant="h6"> { t("poi.description") + " : " } </Typography>
                    <br />
                    {
                        ['cercledesvoyages', 'tropicalementvotre'].includes(quotation_code) ? (
                            <Fragment>
                                {
                                    (activity.short_description !== null || (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: (!activity.is_custom && activity.short_description !== null) ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description')}}/> : ''
                                }
                                {
                                    (activity.long_description !== null || (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html:(!activity.is_custom && activity.long_description !== null) ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description')}}/> : ''
                                }
                            </Fragment>
                        ) : (
                            <Fragment>
                                {
                                    (activity.long_description !== null || (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: activity.long_description !== null ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') }}/>
                                }
                                {
                                    (activity.long_description === null && (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') === '')) && (activity.short_description !== null || (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: activity.short_description != null ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description')}}/>
                                }
                                {
                                    (activity.long_description === null && (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') === '')) && (activity.short_description === null && (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify"> { t("poi.no_description") } </Typography>
                                }
                            </Fragment>
                        )
                    }
                    {
                        ((!activity.is_custom && (activity.good_to_know !== null || localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') !== '')) || (!activity.is_custom && localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') !== '')) && (
                            <Grid>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant="h6"> { t("poi.good_to_know") + " : " } </Typography>
                                <br />
                                <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: activity.good_to_know || localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') }}/>
                                {/*<Typography variant="body1" align="justify">{ localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') }</Typography> */}
                            </Grid>
                        )
                    }
                    {
                        manualVariant !== '' && (localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '' || localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '' || localeText(current_locale.id, manualVariant.localization, manualVariant.good_to_know, 'good_to_know') !== '') &&
                        <Fragment>
                            <br />
                            {
                                (activity.short_description !== null || localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '') && (activity.long_description !== null || localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '') && (activity.good_to_know !== null || localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') !== '') &&
                                <Divider />
                            }
                            <br />
                            <Typography variant="h6"> { localeText(current_locale.id, manualVariant.localization, manualVariant.name, 'title') + " : " } </Typography>
                            <br />
                            {
                                (quotation_code === 'cercledesvoyages' || quotation_code === 'tropicalementvotre') ? (
                                    <Fragment>
                                        <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') }}/>
                                        <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') }}/>
                                        <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.good_to_know, 'good_to_know') }}/>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {
                                            (manualVariant.long_description !== null || (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.is_custom ? (manualVariant.long_description !== null ? manualVariant.long_description : localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description')) : '' }}/>
                                        }
                                        {
                                            (manualVariant.long_description === null && (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') === '')) && (manualVariant.short_description !== null || (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.short_description !== null ? manualVariant.short_description : localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description')}}/>
                                        }
                                        {
                                            (manualVariant.long_description === null && (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') === '')) && (manualVariant.short_description === null && (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify"> { t("poi.no_description") } </Typography>
                                        }
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    }
                </Grid>
            }
            {
                currentSchedule !== null && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.schedule.title") + " : " } </Typography>
                        <br />
                        {
                            openTime !== null && <Typography variant="body1"> {openTime} </Typography>
                        }
                    </Grid>
                )
            }
            {
                ((activity.selected_holidays !== undefined && activity.selected_holidays.length !== 0) || (activity.closed_days !== undefined && activity.closed_days.length !== 0)) && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.schedule.closed_title") + " : " } </Typography>
                        <br/>
                        <Grid container alignItems="baseline" justify="space-between">
                            {
                                activity.selected_holidays !== undefined && activity.selected_holidays.length !== 0 && (
                                    <Grid item>
                                        <Typography className={classes.bold} variant="body1"> { t("poi.schedule.bank_holiday") + " : "} </Typography>
                                        <br/>
                                        {
                                            activity.selected_holidays.map((holiday) => {
                                                return (
                                                    <Typography variant="body1" key={holiday.id}>
                                                        {"- "} { holiday.name !== null ? holiday.name : holiday.international_name }
                                                    </Typography>
                                                );
                                            })
                                        }
                                    </Grid>
                                )
                            }
                            {
                                activity.closed_days !== undefined && activity.closed_days.length !== 0 && (
                                    <Grid item>
                                        <Typography className={classes.bold} variant="body1"> { t("poi.schedule.other_closed_days") + " : " } </Typography>
                                        <br />
                                        {
                                            activity.closed_days.map((closed_day) => {
                                                return (
                                                    <Typography variant="body1" key={closed_day.id}>
                                                        {"- "} { moment.utc(closed_day.day).format(closed_day.repeating ? "DD/MM" : "DD/MM/YYYY") }
                                                    </Typography>
                                                );
                                            })
                                        }

                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
            {
                activity.included !== null && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.included") + " : " } </Typography>
                        <br />
                        <LocaleTextArray localization={ activity.localization } default_text={ activity.included } text_key={ 'included' }/>
                        {/*{*/}
                        {/*activity.included.map((inclusion, inclusion_index) => {*/}
                        {/*return(*/}
                        {/*<Grid container alignItems="center" justify="flex-start" spacing={2} key={inclusion_index}>*/}
                        {/*<Grid item>*/}
                        {/*<CheckIcon className={classes.icon} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                        {/*<Typography variant="body1"> {inclusion} </Typography>*/}
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                        {/*)*/}
                        {/*})*/}
                        {/*}*/}
                    </Grid>
                )
            }
            {
                activity.not_included !== undefined && activity.not_included !== null && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.not_included") + " : " } </Typography>
                        <br />
                        <LocaleTextArray localization={ activity.localization } default_text={ activity.not_included } text_key={ 'not_included' }/>
                        {/*{*/}
                        {/*activity.not_included.map((non_inclusion, non_inclusion_index) => {*/}
                        {/*return(*/}
                        {/*<Grid container alignItems="center" justify="flex-start" spacing={2} key={non_inclusion_index}>*/}
                        {/*<Grid item>*/}
                        {/*<ClearIcon className={classes.icon} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                        {/*<Typography variant="body1"> {non_inclusion} </Typography>*/}
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                        {/*)*/}
                        {/*})*/}
                        {/*}*/}
                    </Grid>
                )
            }
            {
                activity.not_recommended !== undefined && activity.not_recommended !== null && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.not_recommended") + " : " } </Typography>
                        <br />
                        {
                            activity.not_recommended.map((non_recommendation, non_recommendation_index) => {
                                return (
                                    <Grid container alignItems="center" justify="flex-start" spacing={2} key={non_recommendation_index}>
                                        <Grid item>
                                            <WarningIcon className={classes.icon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1"> {non_recommendation} </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                )
            }
            {
                activity.provider !== undefined && activity.provider !== null && activity.provider.code === "viator" && viatorLocation !== null && (viatorLocation.start.length > 0 || viatorLocation.end.length > 0 || viatorLocation.locations.length > 0) && (
                    <Grid>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t('poi.meeting_pickup') } </Typography>
                        {/*<br /> */}
                        {/*<Typography variant="body1"> { t('poi.meeting_pickup_helper') } </Typography> */}
                        {/*<br /> */}
                        {
                            (viatorLocation.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || viatorLocation.meeting_type === 'MEET_EVERYONE_AT_START_POINT') && (viatorLocation.locations.length > 0 || viatorLocation.start.length > 0) &&
                            <Fragment>
                                <Typography variant="h6"> { t('poi.meeting_point') } </Typography>
                                {
                                    viatorLocation.locations.length > 1 &&
                                    <Fragment>
                                        <Typography variant="body1"> { t('poi.meeting_point_helper') } </Typography>
                                        <Autocomplete
                                            options={viatorLocation.locations}
                                            value={meeting_point}
                                            getOptionLabel={(option) => `${option.name} ${option.address !== undefined ? ` : ${option.address}` : ''}`}
                                            onChange={(event, value) => {
                                                dispatch({ type: 'POI_SET_MEETING_POINT', payload: value });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('poi.type_search')} />}
                                        />
                                        {
                                            meeting_point !== undefined && meeting_point !== null && meeting_point.description !== null && meeting_point.description !== "" &&
                                            <Typography variant="body1"> { meeting_point.description } </Typography>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.locations.length === 1 &&
                                    <Fragment>
                                        {
                                            viatorLocation.locations[0].name !== null &&
                                            <Typography variant="body1"> { viatorLocation.locations[0].name } </Typography>
                                        }
                                        {
                                            viatorLocation.locations[0].address !== null &&
                                            <Typography variant="body1"> { viatorLocation.locations[0].address } </Typography>
                                        }
                                        {
                                            viatorLocation.locations[0].description !== undefined && viatorLocation.locations[0].description !== null && viatorLocation.locations[0].description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { viatorLocation.locations[0].description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.locations.length === 0 && viatorLocation.start.length > 1 &&
                                    <Fragment>
                                        <Typography variant="body1"> { t('poi.meeting_point_helper') } </Typography>
                                        <Autocomplete
                                            options={viatorLocation.start}
                                            value={meeting_point}
                                            getOptionLabel={(option) => `${option.name} ${option.address !== undefined ? ` : ${option.address}` : ''}`}
                                            onChange={(event, value) => {
                                                dispatch({ type: 'POI_SET_MEETING_POINT', payload: value });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('poi.type_search')} />}
                                        />
                                        {
                                            meeting_point !== undefined && meeting_point !== null && meeting_point.description !== null && meeting_point.description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { meeting_point.description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.locations.length === 0 && viatorLocation.start.length === 1 &&
                                    <Fragment>
                                        {
                                            viatorLocation.start[0].name !== null &&
                                            <Typography variant="body1"> { viatorLocation.start[0].name } </Typography>
                                        }
                                        {
                                            viatorLocation.start[0].address !== null &&
                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}> { viatorLocation.start[0].address } </Typography>
                                        }
                                        {
                                            viatorLocation.start[0].description !== undefined && viatorLocation.start[0].description !== null && viatorLocation.start[0].description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { viatorLocation.start[0].description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        <br />
                        {
                            (viatorLocation.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || viatorLocation.meeting_type === 'PICKUP_EVERYONE') && (viatorLocation.start.length > 0 || viatorLocation.locations.length > 0) &&
                            <Fragment>
                                <Typography variant="h6"> { t('poi.pickup_point') } </Typography>
                                {
                                    viatorLocation.start.length > 1 &&
                                    <Fragment>
                                        <Typography variant="body1"> { t('poi.pickup_point_helper') } </Typography>
                                        <Autocomplete
                                            options={viatorLocation.start}
                                            value={pickup_point}
                                            getOptionLabel={(option) => `${option.name} ${option.address !== undefined ? ` : ${option.address}` : ''}`}
                                            onChange={(event, value) => {
                                                dispatch({ type: 'POI_SET_PICKUP_POINT', payload: value });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('poi.type_search')} />}
                                        />
                                        {
                                            pickup_point !== undefined && pickup_point !== null && pickup_point.description !== null && pickup_point.description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { pickup_point.description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.start.length === 1 && pickup_point !== null &&
                                    <Fragment>
                                        {
                                            pickup_point.name !== undefined && pickup_point.name !== null && pickup_point.name !== "" &&
                                            <Typography variant="body1"> { pickup_point.name } </Typography>
                                        }
                                        {
                                            pickup_point.address !== undefined && pickup_point.address !== null && pickup_point.address !== "" &&
                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}> { pickup_point.address } </Typography>
                                        }
                                        {
                                            pickup_point.description !== undefined && pickup_point.description !== null && pickup_point.description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { pickup_point.description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.start.length === 0 && viatorLocation.locations.length > 1 &&
                                    <Fragment>
                                        <Typography variant="body1"> { t('poi.pickup_point_helper') } </Typography>
                                        <Autocomplete
                                            options={viatorLocation.locations}
                                            value={pickup_point}
                                            getOptionLabel={(option) => `${option.name} ${option.address !== undefined ? ` : ${option.address}` : ''}`}
                                            onChange={(event, value) => {
                                                dispatch({ type: 'POI_SET_PICKUP_POINT', payload: value });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('poi.type_search')} />}
                                        />
                                        {
                                            pickup_point !== undefined && pickup_point !== null && pickup_point.description !== null && pickup_point.description !== "" &&
                                            <Typography variant="body1"> { pickup_point.description } </Typography>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.start.length === 0 && viatorLocation.locations.length === 1 &&
                                    <Fragment>
                                        {
                                            viatorLocation.locations[0].name !== null &&
                                            <Typography variant="body1"> { viatorLocation.locations[0].name } </Typography>
                                        }
                                        {
                                            viatorLocation.locations[0].address !== null &&
                                            <Typography variant="body1"> { viatorLocation.locations[0].address } </Typography>
                                        }
                                        {
                                            viatorLocation.locations[0].description !== undefined && viatorLocation.locations[0].description !== null && viatorLocation.locations[0].description !== "" &&
                                            <Fragment>
                                                <br />
                                                <Typography variant="body1"> { viatorLocation.locations[0].description } </Typography>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        <br />
                        {
                            viatorLocation.end.length > 0 && 
                            <Fragment>
                                <Typography variant="h6"> { t('poi.end_point') } </Typography>
                                {
                                    viatorLocation.end.length > 1 &&
                                    <Fragment>
                                        <Typography variant="body1"> { "Cette activité se termine à un autre endroit. Voir les détails ci-dessous :" } </Typography>
                                        <Autocomplete
                                            options={viatorLocation.end}
                                            value={return_point}
                                            getOptionLabel={(option) => `${option.name} ${option.address !== undefined ? ` : ${option.address}` : ''}`}
                                            onChange={(event, value) => {
                                                dispatch({ type: 'POI_SET_RETURN_POINT', payload: value });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('poi.type_search')} />}
                                        />
                                        {
                                            return_point !== undefined && return_point !== null && return_point.description !== null && return_point.description !== "" &&
                                            <Typography variant="body1"> { return_point.description } </Typography>
                                        }
                                    </Fragment>
                                }
                                {
                                    viatorLocation.end.length === 1 && return_point !== null &&
                                    <Fragment>
                                        {
                                            viatorLocation.start.length === 1 && pickup_point !== null && pickup_point.google_ref === return_point.google_ref &&
                                            <Typography variant="body1" > { t('poi.same_end_point') } </Typography>
                                        }
                                        {
                                            viatorLocation.start.length === 1 && pickup_point !== null && pickup_point.google_ref !== return_point.google_ref &&
                                            <Fragment>
                                                {
                                                    return_point.name !== undefined && return_point.name !== null && return_point.name !== "" &&
                                                <Typography variant="body1"> { return_point.name } </Typography>
                                                }
                                                {
                                                    return_point.address !== undefined && return_point.address !== null && return_point.address !== "" &&
                                                <Typography variant="body1" style={{ fontWeight: 'bold' }}> { return_point.address } </Typography>
                                                }
                                                {
                                                    return_point.description !== undefined && return_point.description !== null && return_point.description !== "" &&
                                                <Fragment>
                                                    <br />
                                                    <Typography variant="body1"> { return_point.description } </Typography>
                                                </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Grid>
                )
            }
            {
                activity.provider !== undefined && activity.provider !== null && activity.provider.code === "viator" &&
                <Grid>
                    {
                        activity_itinerary !== undefined && activity_itinerary !== null && activity_itinerary.itineraryType === "ACTIVITY" && 
                    <Fragment>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.what_to_expect") } </Typography>
                        {
                            activity_itinerary.activity_info !== undefined && activity_itinerary.activity_info !== null &&
                            <Typography variant="body1"> { activity_itinerary.activity_info.description } </Typography>
                        }
                        <Stepper orientation="vertical" nonLinear>
                            {
                                activity_itinerary.point_interest !== undefined && activity_itinerary.point_interest.map((itinerary, index) => 
                                    (<Step key={itinerary.name} active expanded>
                                        <StepButton>{itinerary.name}</StepButton>
                                    </Step>)
                                )
                            }
                        </Stepper>
                    </Fragment>
                    }
                    {
                        activity_itinerary !== undefined && activity_itinerary !== null && activity_itinerary.itineraryType === "STANDARD" && 
                    <Fragment>
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h6"> { t("poi.what_to_expect") } </Typography>
                        <Stepper orientation="vertical" nonLinear>
                            {
                                activity_itinerary.point_interest.map((itinerary, index) => {
                                    let h = itinerary.duration !== undefined ? Math.floor(itinerary.duration.fixedDurationInMinutes / 60) : 0;
                                    let m = itinerary.duration !== undefined ? itinerary.duration.fixedDurationInMinutes % 60 : 0;
                                    return (
                                        <Step key={itinerary.address} active expanded>
                                            <StepButton>{`${itinerary.name !== undefined && itinerary.name !== null && itinerary.name !== '' ? itinerary.name : ''} ${itinerary.duration !== undefined ? `(${m !== 0 ? moment.utc().hours(h).minutes(m).format("H[h]mm[min]") : moment.utc().hours(h).format("H[h]")})` : (itinerary.pass_by_without_stopping ? `(${t('poi.pass_by')})` : '')}`}</StepButton>
                                            <StepContent>{itinerary.description}</StepContent>
                                        </Step>
                                    );
                                }
                                )
                            }
                        </Stepper>
                    </Fragment>
                    }
                </Grid>
            }
            <Grid>
                {
                    cancellation !== undefined && cancellation !== null &&
                <Fragment>
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6"> { t("poi.cancellations") } </Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: cancellation.description }}/> 
                </Fragment>
                }
                {
                    reviews !== undefined && reviews !== null && reviews.totalReviews !== 0 && reviews.reviewCountTotals !== undefined && reviews.reviewCountTotals !== null && (
                        <Fragment>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.reviews") } </Typography>
                            <Grid container alignItems={"flex-start"}>
                                <Grid container item xs={ 3 }>
                                    <Grid item xs={ 12 }>
                                        <span style={{ fontWeight: 'bold', fontSize: 25, verticalAlign: 2, marginRight: 10 }}>{new Intl.NumberFormat(language).format(parseFloat(reviews.combinedAverageRating).toFixed(1))}</span>
                                        <Rating value={ Math.floor(parseFloat(reviews.combinedAverageRating)) } readOnly />
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <Typography variant="body1"> { reviews.totalReviews } { t("poi.reviews") } </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={ 9 }>
                                    <Grid container direction={'column'} spacing={2}>
                                        {
                                            user.client_full.type !== 2 &&
                                    <Grid item>{t('poi.viator_review', { provider: reviews_source })}</Grid>
                                        }
                                        {renderReview(reviews.reviewCountTotals, reviews.totalReviews)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }
            </Grid>
        </Grid>
    );
};
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#E6592F'
    }
}))(LinearProgress);
export default React.memo(AboutPoi);

// Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel   from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
// Components
import SummaryHeader from './SummaryHeader';
import PlaneLoader from '../PlaneLoader/PlaneLoader.jsx';
// Functions
import { useGetTaxesPrice } from '../CartMaterial/utils/getTaxesPrice';
import GetTripInfo from './Functions/GetTripInfo';
import GetOutOfCart from './Functions/GetOutOfCart';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
// Actions
import { SetTripDocs } from '../../Actions/Menu';
import { SetSummaryStep, SetTripInfo } from '../../Actions/Summary';
import GetPrice from './Functions/GetPrice';
import GetProviders from './Functions/GetProviders';

const useStyles = makeStyles(theme => ({
	absoluteCenter: {
		margin: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	lightGreen: {
		color: '#76B6C2 !important'
	},
	textCenter: {
		textAlign: 'center'
	},
	container: {
		marginTop: 70,
		paddingTop: 32
	},
	paper: {
		padding: 16
	},
	depositTitle: {
		fontSize: 20,
		fontWeight: 'bold'
	},
	orangeButton: {
		backgroundColor: '#E6592F',
		color: 'white'
	},
	genericText: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		color: '#0000008A'
	},
}));

const SummaryPayment = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

	const [isLoading, setIsLoading] = useState(false);
	const [missingDocs, setMissingDocs] = useState(0);
	const [totalPaid, setTotalPaid] = useState(0);
    const [paymentChoices, setPaymentChoices] = useState([]);

	const deposit_amount = useSelector(store => store.summary.deposit_amount);
	const minimal_deposit = useSelector(store => store.summary.minimal_deposit);
	const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const trip_info = useSelector(store => store.summary.trip_info);
	const trip_main_info = useSelector(store => store.summary.trip_main_info);
	const trip_docs = useSelector(store => store.menu.trip_docs);
	const selectedProducts = useSelector(store => store.summary.selectedProducts);
	const itinerary = useSelector(store => store.summary.itinerary);
	const providers = useSelector(store => store.trip.providers);
	const manual_providers = useSelector(store => store.trip.manual_providers);
	const currency_list = useSelector((store) => store.base.currency_list);
    const currency = useSelector(state => state.trip.currency);

	const { headers } = CheckBeforeRequest();
	const getTaxesPrice = useGetTaxesPrice();

	const onPayDeposit = (splitDeposit) => () => {
		if (!splitDeposit && parseFloat(deposit_amount) < parseFloat(minimal_deposit)) {
			enqueueSnackbar(t('summary-payment.minimal-deposit-error'), { variant: 'warning', anchorOrigin: {vertical: 'top', horizontal: 'center'}});
		} else {
			let origin = window.location.origin;
			if (origin.includes('http/')) {
				origin = origin.replace("http", "https");
			}
			let provider = providers.find((el) => el.provider.code === "axepta");
			if (provider === undefined) {
				provider = manual_providers.find((el) => el.provider.code === "axepta");
			}
			if (provider !== undefined) {
				axios({
					method: 'POST',
					url: `${API_HREF}client/${window.id_owner}/payment/get_redirect_url/`,
					headers: headers,
					data: {
						provider_id: provider.provider.id,
						reference: trip_main_info.id + '-' + new Date().toISOString(),
						trip_ref: trip_main_info.trip_reference,
						mail: "dev@facilitatrip.com",
						domain: origin,
						buyer_id: trip_info.contact_traveler_detail.id,
						amount: Math.round(deposit_amount * 100),
						currency_iso_code: trip_info.trip.currency.iso_code,
						success_url: `/${window.url_name}/summary-quotation/payment/success/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`,
						cancel_url: `/${window.url_name}/summary-quotation/payment/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`,
						error_url: `/${window.url_name}/summary-quotation/payment/error/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`,
						notify_url: `/${window.url_name}/summary-quotation/payment/notify/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`
					}
				}).then(response =>{
					window.location.replace(response.data.url);
				}).catch(error => {
					console.log(error);
				});
			} else {
				enqueueSnackbar(t("global.missing_provider"), { variant: "error"});
			}
		}
	};
	const onDepositAmountChange = (e) => {
		dispatch({type: 'SUMMARY_UPDATE_DEPOSIT_AMOUNT', payload: e.target.value});
	};
	useEffect(() => {
		let { pass_check, headers } = CheckBeforeRequest();
		dispatch(SetSummaryStep(7));
		if (trip_info === null) {
			setIsLoading(true);
			GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
		}
        if (pass_check){
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/payment/get_payment_type/`,
                headers: headers
            }).then(function(response){
                let temp_choices = Object.assign({}, response.data);
                temp_choices["DEFAULT"] = t("menu.trip_list.payment_default_type");
                setPaymentChoices(temp_choices);
            }).catch(function(error){
                enqueueSnackbar(t("menu.trip_list.payment_type_error"), { variant: "error"});
            })
        }
	}, []);
	useEffect(() => {
		if (trip_main_info !== null) {
			getDoc();
			GetProviders(dispatch);
		}
	}, [trip_main_info]);
	useEffect(() => {
		if (trip_info !== null) {
			if (trip_info.payments_done.length !== 0) {
				let tmp_total = 0;
				trip_info.payments_done.forEach((payment) => {
					tmp_total += parseFloat(payment.amount);
				});
				if (parseFloat(tmp_total).toFixed(2) === parseFloat(minimal_deposit).toFixed(2) && trip_main_info !== null) {
					if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code)) {
						axios({
							method: "PATCH",
							url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/`,
							headers: headers,
							data: {
								status_contract: "CONFIRMED",
								status_modification: "FIXED_PV",
							},
						}).then((response) => {
							dispatch((SetTripInfo(response.data)))
						}).catch((error) => {
							console.log('error:', error);
						})
					}
				}
				setTotalPaid(tmp_total);
			}
		}
	}, [trip_info]);
	useEffect(() => {
		if (!isLoading) {
			GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch, getTaxesPrice);
		}
	}, [isLoading, getTaxesPrice]);
	useEffect(() => {
		if (trip_docs.length !== 0) {
			let total_requested = 0;
			let total_received = 0;
			trip_docs.map((document) =>{
				if(document.requested === true){
					total_requested += 1;
					if(document.received === true){
						total_received += 1;
					}
				}
			});
			let temp_missing_docs = total_requested - total_received;
			setMissingDocs(temp_missing_docs);
		}
	}, [trip_docs]);
	const getDoc = () => {
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	};
	if (isLoading || trip_info === null) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
	return (
		<Grid container style={{display: 'block'}}>
			<Grid item xs={ 12 }>
				<SummaryHeader missingDocs={ missingDocs } props={props}/>
			</Grid>
			<Grid item>
				<Container className={ classes.container } maxWidth={ 'lg' }>
					<Paper className={ classes.paper }>
						<Grid container spacing={ 2 }>
							<Grid item xs={ 12 }>
								<Typography className={ classes.depositTitle } align={ 'center' }>{ t('summary-payment.deposit-payment') }</Typography>
							</Grid>
							<Grid item xs={ 12 }>
								<Typography>{ t('summary-payment.minimum-deposit') } : { new Intl.NumberFormat(i18n.language).format(minimal_deposit) } { trip_info.trip.currency.symbol }</Typography>
							</Grid>
							<Grid item xs={ 12 }>
								<TextField variant={ 'outlined' } type={ 'number' } value={ deposit_amount } label={ t('summary-payment.deposit-amount') } onChange={ onDepositAmountChange } fullWidth/>
							</Grid>
							<Grid item container justify={ 'flex-end' } xs={ 12 } spacing={ 2 }>
								<Grid item>
									<Button className={ classes.orangeButton } onClick={ onPayDeposit(true) }>{ t('summary-payment.split-deposit') }</Button>
								</Grid>
								<Grid item>
									<Button className={ classes.orangeButton } onClick={ onPayDeposit(false) }>{ t('summary-payment.pay-deposit') }</Button>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
					{
						trip_info !== null && trip_info.payments_done.length !== 0 && (
							<Paper className={ classes.paper } style={{marginTop: 20}}>
								<Grid container spacing={ 2 }>
									{
										parseFloat(totalPaid).toFixed(2) < parseFloat(minimal_deposit).toFixed(2) &&
										<Grid item xs={ 12 }>
											<Alert variant={ 'filled' } severity={ 'warning' }>{ t('summary-payment.partial-deposit') }</Alert>
										</Grid>
									}
									<Grid item xs={ 12 }>
										<Typography className={ classes.depositTitle } align={ 'center' }>{ t('summary-payment.payment-done') }</Typography>
									</Grid>
									<Grid item xs={ 12 }>
										<Grid container direction={ 'column' } spacing={ 2 }>
											<Grid item xs={ 12 } container spacing={ 2 } direction={ 'row' }>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{ t('summary-payment.payment-choice')}
												</Grid>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{t('global.description')}
												</Grid>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{t('menu.trip_list.amount')}
												</Grid>
												<Grid item xs={ 3 } className={`${classes.textCenter} ${classes.genericText}`}>
													{t('global.date')}
												</Grid>
											</Grid>
											{
												trip_info.payments_done.map((payment) => {
													return (
														<Grid key={ payment.id } item xs={ 12 } container direction={ 'row' } spacing={ 2 }>
															<Grid item xs={ 3 } className={`${classes.genericText}`}>
																{ paymentChoices[payment.payment_type]}
															</Grid>
															<Grid item xs={ 3 } className={`${classes.genericText}`}>
																{payment.description !== null ? payment.description : "-"}
															</Grid>
															<Grid item xs={ 3 } className={`${classes.genericText}`}>
																{ new Intl.NumberFormat(i18n.language, { style: 'currency', currency: payment.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payment.amount) }
															</Grid>
															<Grid item xs={ 3 } className={`${classes.textCenter} ${classes.genericText}`}>
																{moment.utc(payment.done_date).format("L")}
															</Grid>
														</Grid>
													)
												})
											}
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						)
					}
				</Container>
			</Grid>
		</Grid>
	);
};

export default SummaryPayment;